import { useEffect, useState } from 'react'
import { SignIn } from '@clerk/nextjs'
import { Center } from '@mantine/core'
import { getBaseUrlSafe } from '@purposity/utils'

function LoginPage() {
  const [redirectUrl, setRedirectUrl] = useState<string>()

  useEffect(() => {
    if (typeof window !== 'undefined')
      setRedirectUrl(new URL('/home', getBaseUrlSafe()).href)
  }, [])

  return (
    <Center w="100vw">
      <SignIn
        routing="path"
        redirectUrl={redirectUrl}
        appearance={{
          layout: {
            logoPlacement: 'outside',
          },
          elements: {
            logoBox: {
              margin: 24,
              justifyContent: 'center',
            },
            footerAction__signIn: {
              display: 'none',
            },
          },
        }}
      />
    </Center>
  )
}

export default LoginPage
